import React from "react"
import Layout from "../components/layout"
import { useStaticQuery, graphql } from "gatsby"
import SEO from "../components/seo"
import { PageHeader, Services } from "../components/sections"
import {
  office365,
  HelpDigital,
  contentMigration,
  customapplication,
  digitalworkplace,
  intranetservice,
  processautomation,
} from "../images/digitalworkplace"
import {
  BlackSection,
  DetailSection,
  IntroSection,
  OfferSection,
  Points,
} from "../components/structure"
import { AboutCard } from "../components/elements"

const weOffer = [
  {
    id: 1,
    src: processautomation,
    title: "Process Automation and Digitisation",
    content:
      "Don’t let repetitive manual processes keep your business from reaching its full potential. Increase productivity and revenue through automating and digitising your internal processes. Our automation solutions are also cost-effective, helping your business to save in the long run.",
    order: 0,
  },
  {
    id: 2,
    src: intranetservice,
    title: "Intranet Services",
    content:
      "An effective intranet system can surge business output, as well as improve your organisation’s social climate. Our intranet services include everything from content and server migration to custom platform and application design and building.",
    order: 1,
  },
  {
    id: 3,
    src: office365,
    title: "Office 365",
    content:
      "It’s time to let Office 365 work for you. We’ll show you how to drive growth in your business through utilising your Microsoft subscriptions, configure the best platforms for your organisation, migrate your files into OneDrive, and create a custom user-friendly Portal with links to your OneDrive files.",
    order: 0,
  },
  {
    id: 4,
    src: contentMigration,
    title: "Content Migration",
    content:
      "Our content migration services easily transfer the data from one content management system, document management system, or flat HTML-based system to another. Let us take care of migrating your content quickly, efficiently, and securely, so you can focus on the heart of your business.",
    order: 1,
  },
  {
    id: 5,
    src: customapplication,
    title: "Custom Application Development Services ",
    content:
      "A customised app can be a powerful tool that will set your business apart from the competition and stimulate growth. We work closely with you to design, develop, and maintain the ideal app that meets your business needs (and those of your customers).",
    order: 0,
  },
]

const points = [
  {
    id: 1,
    point: "Be organised and productive",
  },
  {
    id: 2,
    point: "Have an edge over the competition",
  },
  {
    id: 3,
    point: "Withstand pandemic restrictions",
  },
  {
    id: 4,
    point: "Cut costs by digitising manual processes",
  },
  {
    id: 5,
    point: "Stay connected at an internal and external level",
  },
]
export default function DigitalWorkplace() {
  const data = useStaticQuery(graphql`
    query {
      index: file(relativePath: { eq: "headers/dataProduct.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  const index = [
    data.index.childImageSharp.fluid,
    `linear-gradient(0deg,
      rgba(0, 0, 0, 0.30),
      rgba(0, 0, 0, 0.30))`,
  ].reverse()

  return (
    <Layout>
      <SEO title="Digital Workplace" />
      <PageHeader
        fluid={index}
        title="Watch your business thrive in the digital age"
        catchy="Digital Workplace"
        subtitle="Let us take your business to the next level"
      />
      <IntroSection
        title="Digital Workplace"
        content="The Covid-19 pandemic has shown us that going digital helps businesses to prosper in dire circumstances. All businesses can benefit from a digital workplace, and we have the tools, products, and know-how to set up the ideal online workstation for you. Strengthen and grow your business by utilising technology that will keep your business running, no matter what happens in the physical world. "
        src={digitalworkplace}
      />
      <BlackSection title="Let us take your business to the next level" />
      <IntroSection
        title="How we will help you?"
        content="Having an effective digital workplace behind you will help your business to succeed in a world shaped by physical and geographical restrictions. Our digital workplace solutions increase the efficiency of your business by digitising dated processes, connecting personnel through an advanced intranet system, managing your content securely in a digital space, and utilising effective tools such as Office 365 platforms and custom-designed apps. Let us take your company to the next level by reaping the rewards of going digital."
        src={HelpDigital}
      />
      <OfferSection title="What we offer:">
        {weOffer.map((item, id) => (
          <AboutCard
            key={item.id}
            order={item.order}
            src={item.src}
            to={item.to}
            title={item.title}
            content={item.content}
          />
        ))}
      </OfferSection>
      <DetailSection paragraph1="A digital workplace will help your business to prosper, even in the face of a pandemic. The benefits of our digital workplace solutions are endless, ranging from connecting the faces of your business through an intranet system to increasing productivity through Office 365. By using our digital workplace services, you will help your business to:">
        {points.map((item, id) => (
          <Points key={item.id}>{item.point}</Points>
        ))}
      </DetailSection>
      <Services />
      <BlackSection title="Book a consultation to find out more today." />
    </Layout>
  )
}
